body {
    margin: 0;
    font-family: 'Roboto';
    /* font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI"; */
    /* font-family: Rubik, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import'tailwindcss/base';
@import'tailwindcss/components';
@import 'tailwindcss/utilities'; */